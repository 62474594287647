<template>
  <b-card no-body>
    <b-card-body>
        <b-row>
          <b-col cols="12" md="3"
            class="mb-md-0 mb-2">
            <b-form-group
              :label="$t('Belge Tarihi Başlangıç')"
              label-for="dtBaslangicFilter"
            >
              <b-form-input type="date" v-model="dtBaslangicFilter" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
             <b-form-group
              :label="$t('Belge Tarihi Bitiş')"
              label-for="dtBitisFilter">
              <b-form-input type="date" v-model="dtBitisFilter" />
            </b-form-group>
          </b-col>
           <b-col cols="12" md="3">
          <b-form-group label="" label-for="">
            <label for="entityIdFilter">
              {{ $t("Müşteri Seçiniz") }}
            </label>
            <musteri-selection 
              :is-uyum-entity-id="true"
              :musteri-select.sync="entityIdFilter" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group>
            <label for="siparisDurumFilter">
              {{ $t("Durum Seçiniz") }}
            </label>
            <siparis-durum-selection
              :siparis-durum-select.sync="siparisDurumFilter"
            />
          </b-form-group>
        </b-col>
        </b-row>
    </b-card-body>
</b-card>
</template>

<script>
import MusteriSelection from '../selection/MusteriSelection.vue';
import SiparisDurumSelection from '../selection/SiparisDurumSelection.vue';

export default {
  components: {
    MusteriSelection,
    SiparisDurumSelection,
  },
  computed: {
    dtBaslangicFilter: {
      get() {
        return this.$store.getters['siparisYonetim/getSiparisFilters'].dtBaslangic
      },
      set(value) {
        this.$store.commit('siparisYonetim/setSiparisFilter', { key: 'dtBaslangic', value })
      },
    },
    dtBitisFilter: {
      get() {
        return this.$store.getters['siparisYonetim/getSiparisFilters'].dtBitis
      },
      set(value) {
        this.$store.commit('siparisYonetim/setSiparisFilter', { key: 'dtBitis', value })
      },
    },
    siparisDurumFilter: {
      get() {
        return this.$store.getters['siparisYonetim/getSiparisFilters'].siparisDurum
      },
      set(value) {
        this.$store.commit('siparisYonetim/setSiparisFilter', { key: 'siparisDurum', value })
      },
    },
    entityIdFilter: {
      get() {
        return this.$store.getters['siparisYonetim/getSiparisFilters'].entityId
      },
      set(value) {
        this.$store.commit('siparisYonetim/setSiparisFilter', { key: 'entityId', value })
      },
    },
  },
}
</script>
