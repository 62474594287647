<template>
  <div>
     <!-- Buttons -->
    <crud-button :onClickList="fetchSiparisList" :showAddNew="false" />
    <!-- Filters -->
    <siparis-filters />
    <b-card no-body class="p-2">
      <DxDataGrid
        id="gridContainer"
        :height="600"
        :ref="dataGridRefName"
        :data-source="siparisList"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :selection="{ mode: 'single' }"
        :hover-state-enabled="true"
        :column-auto-width="true"
        key-expr="pId"
        @rowDblClick="onSiparisSelect"
      >
        <DxFilterRow :visible="true" :apply-filter="currentFilter" />
        <DxHeaderFilter :visible="true" />
        <DxPaging :enabled="false" />
        <DxSearchPanel :visible="true" :width="240" :placeholder="$t('Ara...')" />
        <DxColumn
          data-field="siparisDurumu"
          cell-template="completeStatusTemplate"
          caption="Durum"
        />
        <DxColumn
          data-field="pId"
          caption="Id"
        />
        <DxColumn
          :width="100"
          data-field="isYeriKodu"
          caption="İşyeri Kodu"
        />
        <DxColumn
          :width="120"
          data-field="belgeTarih"
          caption="Belge Tarih"
          alignment="right"
          data-type="date"
          sort-order="desc"
        />
        <DxColumn
          data-field="belgeNo"
          caption="Belge No"
        />
        <DxColumn
          data-field="hareketKodu"
          caption="Hareket Kodu"
        />
        <DxColumn
          data-field="cariKodu"
          caption="Cari Kodu"
        />
        <DxColumn
          data-field="cariAdi"
          caption="Cari Adı"
        />
        <DxColumn
          data-field="transportTypeDesc"
          caption="Bayi"
        />
        <DxColumn
          data-field="salesPersonName"
          caption="Satış Temsilcisi"
        />
        <DxColumn
          data-field="paraBirimi"
          caption="Para Birim"
        />
        <DxColumn
          data-field="kur"
          caption="Kur"
          alignment="right"
        />
        <DxColumn 
          data-field="alisSatisDurumu"
          caption="Alış/Satış" />
        <DxColumn 
          data-field="malTutari"
          caption="Mal Tutarı" 
          alignment="right"
          :customize-text="customizeDecimalDxGrid" />
        <DxColumn 
          data-field="toplamIskontoTutari"
          caption="Isk.Toplam Tutarı" 
          alignment="right"
          :customize-text="customizeDecimalDxGrid" />
        <DxColumn 
          data-field="kdvTutari"
          caption="Kdv Tutarı"
          alignment="right"
          :customize-text="customizeDecimalDxGrid" />
        <DxColumn 
          data-field="genelToplam"
          caption="Genel Toplam" 
          alignment="right"
          :customize-text="customizeDecimalDxGrid" />
        <template #completeStatusTemplate="{ data }">
          <div>
            <b-badge v-show="data.value === 0">{{ $t('Beklemede') }} </b-badge>
            <b-badge variant="primary" v-show="data.value === 1">{{ $t('Ödeme Yapıldı') }}</b-badge>
            <b-badge variant="success" v-show="data.value === 2">{{ $t('Onaylandı') }}</b-badge>
            <b-badge variant="success" v-show="data.value === 3">{{ $t('Kurulum Yapıldı') }}</b-badge>
            <b-badge variant="success" v-show="data.value === 4">{{ $t('Kapatıldı') }}</b-badge>
            <b-badge variant="danger" v-show="data.value === 5">{{ $t('Reddedildi') }}</b-badge>
          </div>
        </template>
        <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
        <template #masterDetailTemplate="{ data: siparisData }">
          <siparis-detail :siparisDetayData="siparisData" />
        </template>
      </DxDataGrid>
    </b-card>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxHeaderFilter,
  DxSearchPanel,
  DxFilterRow,
  DxMasterDetail,
} from 'devextreme-vue/data-grid';

import { locale } from 'devextreme/localization';
import { customizeDecimalDxGrid } from '@core/utils/filter';
import CrudButton from '@/components/CrudButton.vue';
import SiparisFilters from './SiparisFilters.vue'
import SiparisDetail from './SiparisDetail.vue';

export default {
  components: {
    CrudButton,
    SiparisFilters,
    SiparisDetail,

    DxDataGrid,
    DxPaging,
    DxColumn,
    DxHeaderFilter,
    DxSearchPanel,
    DxFilterRow,
    DxMasterDetail,
  },
  data() {
    return {
      currentFilter: 'auto',
      dataGridRefName: 'dataGrid',
    }
  },
  computed: {
    siparisList() {
      return this.$store.getters['siparisYonetim/getSiparises']
    },
  },
  methods: {
    fetchSiparisList() {
      this.$store.dispatch('siparisYonetim/fetchSiparises')
    },
    onSiparisSelect(data) {
      if (data.data.pSiparisRefId === null || data.data.pSiparisRefId === 0) {
        this.$router.push({ name: 'manage-siparis-detay', params: { id: data.key } })
      } else {
        this.$router.push({ name: 'manage-siparis-yenileme-detay', params: { id: data.key } })
      }
    },
    customizeDecimalDxGrid,
  },
  mounted() {
    this.fetchSiparisList();
  },
  created() {
    locale(navigator.language)
  },
}
</script>

<style scoped>
#gridContainer {
  height: 440px;
}

.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option > span {
  margin-right: 10px;
}

.option > .dx-selectbox {
  display: inline-block;
  vertical-align: middle;
}
</style>
